<template>
  <div>
    <div class="produto-editar">
      <div class="conteudo-interno">
        <div class="nova-comunicacao-container">
          <h1>Editar Prêmio</h1>
          <transition name="fade">
            <formulario-cadastro-premios :editar="true" />
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FormularioCadastroPremios from "@/components/elementos/paginas/FormularioCadastroPremios";
export default {
  name: "CadastroPremiosEditar",
  components: {
    FormularioCadastroPremios,
  },
};
</script>

<style lang="scss">
.produto-editar {
  position: relative;
  display: flex;
  overflow: hidden;
  width: 100%;
  height: 100%;
}
</style>
